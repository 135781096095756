<template>
    <!-- 影像课堂路由 -->
    <div class="video_box">
        <banner-carousel></banner-carousel>
        <video-list></video-list>
    </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

export default {
    components:{
        bannerCarousel: () => import('@/components/bannerCarousel'),
        videoList: () => import('@/components/videoList'),
    },
    methods: {
        ...mapMutations([
            "clearList",
        ]),
        ...mapActions([
            "getVideoBanner",
            "getVideoList",
            "getVideoType"
        ]),
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.getVideoBanner();
            // vm.getVideoList("1001");
            vm.getVideoType();
        });
    },
    beforeRouteLeave (to, from, next) {
        this.clearList();
        next();
    },
}
</script>

<style scoped>
.video_box{
    padding-bottom: 80px;
    background-color: #fafafa;
    /* background-image: url('../assets/images/bg.jpg'); */
    background-size: cover;
}
</style>
